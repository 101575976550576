import React from 'react';
import '../css/Footer.css';
const Footer = () => {
  return (
    <div className='footer'>
      <small className='small'>&copy; 2024 Stack Technologies Inc.</small>
      <div className='f-wrapper'>
        <a href="/terms" className=''>Terms Of Use</a>
        <a href="/privacy" className=''>Privacy Policy</a>
      </div>
    </div>
  );
};

export default Footer;
