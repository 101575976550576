import React, { useEffect } from 'react';
import axios from 'axios';
import purchaseSuccessImg from '../assets/purchase-success.png';
import '../css/PurchaseSuccess.css';

const PurchaseSuccess = () => {
  useEffect(() => {
    handlePurchase();
    return () => {
      // Clean up if necessary
    };
  }, []);

  const handlePurchase = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const voucherCode = searchParams.get('voucherCode');
    const fromEmail = searchParams.get('fromEmail');
    const toEmail = searchParams.get('toEmail');
    const receiverName = searchParams.get('receiverName');
    const senderName = searchParams.get('senderName');
    const message = searchParams.get('message');
    const design = searchParams.get('design');
    const purchaseId = searchParams.get('purchaseId');

    if (
      !voucherCode ||
      !receiverName ||
      !senderName ||
      !fromEmail ||
      !toEmail
    ) {
      console.error('Missing required fields');
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(fromEmail) || !emailRegex.test(toEmail)) {
      console.error('Invalid email address');
      return;
    }

    try {
      console.log('API Base URL:', process.env.REACT_APP_API_BASE_URL);
      const API_BASE_URL =
        process.env.REACT_APP_API_BASE_URL || 'https://api.giveabit.gift';
      const response = await axios.post(
        `${API_BASE_URL}/api/vouchers/purchase/success`,
        {
          voucherCode,
          fromEmail,
          toEmail,
          receiverName,
          senderName,
          message,
          design,
          purchaseId,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (response.status === 200) {
        console.log('Purchase success handled');
      } else {
        console.error('Error handling purchase success:', response.status);
      }
    } catch (error) {
      console.error('Error handling purchase success:', error);
    }
  };

  return (
    <div className='purchase-successs-conatiner'>
      <div className='purchase-successs-wrapper'>
        <div className='purchase-success'>
          <img src={purchaseSuccessImg} alt='Purchase Success' />
          <h1>And Off It Goes! Your BitGift is on Its Way.</h1>
          <p>
            Thank you for your purchase. You've just done something remarkable.
            Sit back, and imagine their smile when they see what you've sent.
          </p>
          <button>Send Another BitGift</button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSuccess;
