import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/VoucherRedemption.css';
import BitcoinChart from './BitcoinChart';
import logo from '../assets/stack-card-logo-white.png';

const RedeemVoucher = () => {
  const [code, setCode] = useState(['', '', '', '', '', '', '', '', '']);
  const [message, setMessage] = useState('');
  const [isCodeEntered, setIsCodeEntered] = useState(false);
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [priceChange, setPriceChange] = useState(null);
  const [voucher, setVoucher] = useState(null);
  const navigate = useNavigate();

  const handleCodeChange = (e, index) => {
    const value = e.target.value.toUpperCase();
    const updatedCode = [...code];
    updatedCode[index] = value;
    setCode(updatedCode);

    if (value.length === 1 && index < 8) {
      const nextInput = e.target.nextSibling;
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedCode = e.clipboardData.getData('text');
    if (pastedCode.length === 9) {
      const updatedCode = pastedCode.split('');
      setCode(updatedCode);
    }
  };

  const handleRedeemClick = async () => {
    const enteredCode = code.join('').toLowerCase();
  
    if (enteredCode.length === 9) {
      setIsCodeEntered(true);
      try {
        const response = await axios.get(`https://api.giveabit.gift/api/vouchers/${enteredCode}`, {
          headers: {
            'Accept': 'application/json'
          }
        });
  
        if (response.headers['content-type'].includes('application/json')) {
          const voucherData = response.data;
  
          // Assign default design if none is set
          if (!voucherData.design) {
            voucherData.design = 'design1';
          }
  
          setVoucher(voucherData);
        } else {
          setMessage('Unexpected response format. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching voucher details:', error);
        setMessage('Error fetching voucher details. Please try again.');
      }
    } else {
      setMessage('Please enter a valid 9-digit voucher code.');
    }
  };

  const handleTransferClick = () => {
    navigate('/wallet-selection', { state: { voucherCode: voucher.code } });
  };

  const formatCurrency = (value) => {
    if (typeof value === 'number') {
      return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }
    return 'N/A';
  };

  return (
    <div className='redeem-voucher'>
      {!isCodeEntered ? (
        <div className='redeem-voucher-wrapper'>
          <h1>Unlock Your Piece of Tomorrow.</h1>
          <p>
            You've received a token of potential. Let's make it yours. Enter
            your BitGift code to transfer your Bitcoin to your wallet.
          </p>
          <div className='code-input-grid'>
            {code.map((digit, index) => (
              <input
                key={index}
                type='text'
                maxLength='1'
                value={digit}
                onChange={(e) => handleCodeChange(e, index)}
                onPaste={index === 0 ? handlePaste : undefined}
              />
            ))}
          </div>
          <button onClick={handleRedeemClick}>Redeem</button>
        </div>
      ) : (
        <div className='redeem-voucher-wrapper'>
          <h1>Your BitGift</h1>
          <p>
            Here are the details of your BitGift. Check out the USD value, current Bitcoin value, and the latest Bitcoin price. 
            Keep your BitGift code safe and transfer it to your wallet when you're ready.
          </p>
          <div className='voucher-grid'>
            {voucher ? (
              <>
                <div className='voucher-card'>
                  <div className={`card-design ${voucher.design}`}>
                    <img src={logo} alt='Logo' className='card-logo' />
                    <div className='btc-value'>
                      {parseFloat(voucher.bitcoinValue).toFixed(6)}
                    </div>
                    <div className='btc-text'>BTC</div>
                    <div className='personal-message'>
                      {voucher.message && voucher.message.trim()
                        ? voucher.message
                        : voucher.purchaserName
                        ? `${voucher.purchaserName}
                   sent you a BitGift`
                        : 'A BitGift just for you'}
                    </div>
                  </div>
                </div>
                <div className='current-value'>
                  <h2>Current BitGift Value</h2>

                  <p>
                    ${parseFloat(voucher.value).toFixed(2)} <br />
                    <span>USD</span>
                    </p>
                    {priceChange !== null && (
                    <span>
                      Your BitGift's Bitcoin value has changed by {priceChange.toFixed(2)}% today
                    </span>
                  )}
                </div>
                <div className='transfer-section'>
                  Transfer
                  <button onClick={handleTransferClick}>
                    Send to your wallet
                  </button>
                  <ul>
                    <div>
                      <b>Don't have a wallet yet?</b>
                    </div>
                    <li>
                      <a href='https://www.coinbase.com/join/ZX32MD9'>Sign up for Coinbase</a>
                    </li>
                    <li>
                      <a href='https://www.walletofsatoshi.com/'>Download Wallet of Satoshi</a>
                    </li>
                  </ul>
                </div>
                <div className='bitcoin-growth'>
                  <h2>Bitcoin Growth Rate</h2>
                  <span>{formatCurrency(bitcoinPrice)}</span>
                  <BitcoinChart setBitcoinPrice={setBitcoinPrice} setPriceChange={setPriceChange} priceChange={priceChange} />
                </div>
                <div className='quote'>
                  <p>
                    "Someone's sitting in the shade today because someone
                    planted a tree a long time ago."
                  </p>
                  <span>
                    <i>- Warren Buffet</i>
                  </span>
                </div>
                <div className='learn-more'>
                  <h2>Learn more about Bitcoin</h2>
                  <ul>
                    <li>
                      <a href="https://bitcoin.org/en/">What is Bitcoin?</a>
                    </li>
                    <li>
                      <a href="https://www.investopedia.com/terms/b/bitcoin.asp">Bitcoin Basics</a>
                    </li>
                    <li>
                      <a href="https://www.bitcoin.com/get-started/">Getting Started</a>
                    </li>
                    <li>
                      <a href="https://www.coinbase.com/learn">Crypto Learning Hub</a>
                    </li>
                    <li>
                      <a href="https://academy.binance.com/en/articles/what-is-bitcoin">Bitcoin Guide</a>
                    </li>
                    <li>
                      <a href="https://www.blockchain.com/learning-portal">Blockchain Basics</a>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <p>Loading voucher details...</p>
            )}
          </div>
        </div>
      )}
      {message && <p>{message}</p>}
    </div>
  );
};

export default RedeemVoucher;