// src/components/BitcoinChart.js
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { getBitcoinData } from '../coinrankingService';
import '../css/BitcoinChart.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, TimeScale, TimeSeriesScale);

const BitcoinChart = ({ setBitcoinPrice, setPriceChange, priceChange }) => {

  const [chartData, setChartData] = useState({});
  const [timePeriod, setTimePeriod] = useState('24h');

  useEffect(() => {
    const fetchData = async () => {
      const data = await getBitcoinData(timePeriod);
  
      if (data && data.data && data.data.coin) {
        const coinSparkline = data.data.coin.sparkline;
  
        if (coinSparkline && coinSparkline.length > 0) {
          const chartLabels = Array.from({ length: coinSparkline.length }, (_, i) => i);
          const chartPrices = coinSparkline.map(price => parseFloat(price));

          setChartData({
            labels: chartLabels,
            datasets: [
              {
                label: 'Bitcoin Price',
                data: chartPrices,
                borderColor: '#5816a7',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                pointRadius: 0,
                borderWidth: 3,
              },
            ],
          });
        } else {
          console.warn('No coin sparkline data available');
        }
  
        const currentPrice = parseFloat(data.data.coin.price);
        setBitcoinPrice(currentPrice);
        
        const change = parseFloat(data.data.coin.change);
        setPriceChange(change);
      } else {
        console.warn('Invalid API response:', data);
      }
    };
    fetchData();
  }, [timePeriod, setBitcoinPrice, setPriceChange]);

  // const formatPercentage = (value) => {
  //   if (value !== null && typeof value === 'number') {
  //     return `${value.toFixed(2)}%`;
  //   }
  //   return 'N/A';
  // };

  const options = {
    scales: {
      x: {
        display: false, // Hide the x-axis
      },
      y: {
        display: false, // Hide the y-axis
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    elements: {
      line: {
        borderWidth: 2, // Adjust the line width if needed
      },
    },
  };

  return (
    <div className='bitcoin-container'>
      {chartData.labels && chartData.labels.length > 0 ? (
        <Line data={chartData} options={options} />
      ) : (
        <p>No chart data available.</p>
      )}
      <div className='time-buttons'>
        <button
          className={`time-button ${timePeriod === '24h' ? 'selected' : ''}`}
          onClick={() => setTimePeriod('24h')}
        >
          24H
        </button>
        <button
          className={`time-button ${timePeriod === '7d' ? 'selected' : ''}`}
          onClick={() => setTimePeriod('7d')}
        >
          1W
        </button>
        <button
          className={`time-button ${timePeriod === '30d' ? 'selected' : ''}`}
          onClick={() => setTimePeriod('30d')}
        >
          1M
        </button>
        <button
          className={`time-button ${timePeriod === '1y' ? 'selected' : ''}`}
          onClick={() => setTimePeriod('1y')}
        >
          1Y
        </button>
        <button
          className={`time-button ${timePeriod === '5y' ? 'selected' : ''}`}
          onClick={() => setTimePeriod('5y')}
        >
          All
        </button>
      </div>
      <div className='line-chart'></div>
    </div>
  );
};

export default BitcoinChart;
